<template>
    <div>
        <div class="c-log-list" v-if="hasPermission('MerchantCentral/listOrderPaymentEvents')">
            <p class="c-log-list__header">
                <mercur-input v-model="searchTerm">
                    <template #prefix><i class="fas fa-search"></i></template>
                </mercur-input>
            </p>
            <div class="c-log-list__wrapper">
                <div class="mt-3 p-3" v-if="loading">
                    <mercur-spinner></mercur-spinner>
                </div>
                <div class="c-log-list__body" ref="logBox" v-if="events">
                    <div class="c-log-list__item" v-for="item in events" :key="item.dateCreated">
                        <p><strong><span class="c-log-list__item-icon"><i class="fas fa-info-circle"></i></span>{{ item.event }}</strong></p>
                        <p class="c-log-list__item-note">{{ item.dateCreated }}</p>
                        <payload-display :payload="item.payload"></payload-display>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PayloadDisplay from '@/components/elements/order/PayloadDisplay'
import CONFIG from '@root/config'

export default {
    name: 'PaymentEvents',
    components: { PayloadDisplay },
    props: {
        keywords: {
            default: () => [],
        },
    },
    data () {
        return {
            originalEvents: null,
            events: null,
            loading: false,
            searchTerm: '',
        }
    },
    watch: {
        searchTerm (value) {
            this.search(value)
        },
    },
    methods: {
        listPaymentEvents () {
            this.loading = true
            const url = CONFIG.API.ROUTES.ORDERS.LIST_PAYMENT_EVENTS.replace('{orderId}', this.$route.params.orderId)
            this.get(url).then(({ data }) => {
                this.originalEvents = data
                this.events = JSON.parse(JSON.stringify(this.originalEvents))
                this.loading = false
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },
        search (value) {
            const original = JSON.parse(JSON.stringify(this.originalEvents))
            const val = value.toLowerCase()
            this.$set(this, 'events', original.map((e) => {
                return {
                    ...e,
                    payload: this.searchPayload(val, e.payload, true),
                }
            }))
            this.$forceUpdate()
        },
        searchPayload (val, payload, firstLoop = false) {
            if (Array.isArray(payload) && firstLoop) {
                payload.forEach((values) => {
                    this.searchPayload(val, values)
                })
                return payload
            }

            for (let key in payload) {
                if (payload.hasOwnProperty(key) && typeof payload[key] === 'object') {
                    if (payload[key]) { this.searchPayload(val, payload[key]) }
                } else {
                    if (!key.toLowerCase().includes(val) && (payload[key] && !payload[key].toString().toLowerCase().includes(val))) {
                        delete payload[key]
                    }
                }
            }

            return payload
        },
    },

    created () {
        if (!this.hasPermission('MerchantCentral/listOrderPaymentEvents')) {
            return
        }
        this.listPaymentEvents()
    },
    mounted () {
        this.$bus.$on('fetchLog', this.listPaymentEvents)
    },
    destroyed () {
        this.$bus.$off('fetchLog', this.listPaymentEvents)
    },
}
</script>

<style lang="scss" scoped>
    .c-log-list {
        border: 1px solid #d6d6d6;
        height: 320px;
        padding-top: 75px;
        position: relative;

        &__wrapper {
            overflow-y: scroll;
            height: 100%;
        }

        &__header {
            color: #666666;
            padding: 10px 15px;
            position: absolute;
            margin: 0;
            background-color: white;
            width: 100%;
            z-index: 2;
            top: 0;
            border-bottom: 1px solid #d6d6d6;
        }

        &__search {
            margin: 0 !important;
            min-height: auto !important;
            padding: 0 !important;
        }

        &__item {
            font-size: 12px;
            border-bottom: 1px solid rgba(151,151,151, .2);
            padding: 10px 0 10px 40px;

            p {
                margin: 0;
                line-height: 20px;
                position: relative;
            }

            &:nth-last-child(1) {
                border: none;
            }

            &-note {
                font-size: 11px;
            }

            &-icon {
                font-size: 14px !important;
                position: absolute;
                left: -25px;
                margin-top: -2px;
            }
        }
    }
</style>
