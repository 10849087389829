<template>
    <div>
        <div class="c-log-list" v-if="hasPermission('MerchantCentral/listEventsForOrder')">
            <p class="c-log-list__header">
                <mercur-input class="mb-0" v-model="searchTerm">
                    <template #prefix><i class="fas fa-search"></i></template>
                </mercur-input>
            </p>
            <div class="c-log-list__wrapper">
                <div class="mt-3 p-3" v-if="loading">
                    <mercur-spinner></mercur-spinner>
                </div>
                <div class="c-log-list__body" ref="logBox" v-if="logs">
                    <div class="c-log-list__item" v-for="item in logs" :key="item.createdAt.date">
                        <p><strong><span class="c-log-list__item-icon"><i class="fas fa-info-circle"></i></span>{{ item.eventName }}</strong></p>
                        <p class="c-log-list__item-note">{{ item.createdAt.date }}</p>
                        <payload-display :payload="item.payload"></payload-display>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PayloadDisplay from '@/components/elements/order/PayloadDisplay'
import CONFIG from '@root/config'

export default {
    name: 'LogList',
    components: { PayloadDisplay },
    props: {
        keywords: {
            default: () => [],
        },
    },
    data () {
        return {
            filtered: null,
            originalLogs: null,
            logs: null,
            loading: false,
            searchTerm: '',
        }
    },
    watch: {
        searchTerm (value) {
            this.search(value)
        },
    },
    methods: {
        fetchHistory () {
            this.loading = true
            const url = CONFIG.API.ROUTES.ORDERS.GET_HISTORY
                .replace('{orderId}', this.$route.params.orderId)
                .replace('{accountId}', this.$route.params.accountId)
            this.get(url).then(({ data }) => {
                let filteredData = data
                if (this.keywords.length) {
                    filteredData = filteredData.filter((e) => {
                        return this.keywords.some((word) => e.eventName.toLowerCase().includes(word.toLowerCase()))
                    })
                }

                if (this.keywords.includes('order line')) {
                    filteredData = filteredData.filter((e) => {
                        return e.payload.orderLineId === this.$route.params.orderLineId || !e.eventName.toLowerCase().includes('order line')
                    })
                }

                if (this.keywords.includes('complaint')) {
                    filteredData = filteredData.filter((e) => {
                        return e.payload.complaint ? e.payload.complaint.orderLineId === this.$route.params.orderLineId : true
                    })
                }

                this.originalLogs = filteredData
                this.logs = JSON.parse(JSON.stringify(this.originalLogs))
            }).finally(() => {
                this.loading = false
            })
        },
        search (value) {
            const original = JSON.parse(JSON.stringify(this.originalLogs))
            const val = value.toLowerCase()
            this.$set(this, 'logs', original.map((e) => {
                return {
                    ...e,
                    payload: this.searchPayload(val, e.payload, true),
                }
            }))
            this.$forceUpdate()
        },
        searchPayload (val, payload, firstLoop = false) {
            if (Array.isArray(payload) && firstLoop) {
                payload.forEach((values) => {
                    this.searchPayload(val, values)
                })
                return payload
            }

            for (let key in payload) {
                if (payload.hasOwnProperty(key) && typeof payload[key] === 'object') {
                    if (payload[key]) { this.searchPayload(val, payload[key]) }
                } else {
                    if (!key.toLowerCase().includes(val) && (payload[key] && !payload[key].toString().toLowerCase().includes(val))) {
                        delete payload[key]
                    }
                }
            }

            return payload
        },
    },

    created () {
        if (!this.hasPermission('listEventsForOrder')) {
            return
        }
        this.fetchHistory()
    },
    mounted () {
        this.$bus.$on('fetchLog', this.fetchHistory)
    },
    destroyed () {
        this.$bus.$off('fetchLog', this.fetchHistory)
    },
}
</script>

<style lang="scss" scoped>
    .c-log-list {
        border: 1px solid #d6d6d6;
        height: 320px;
        padding-top: 60px;
        position: relative;

        &__wrapper {
            overflow-y: scroll;
            height: 100%;
        }

        &__header {
            color: #666666;
            padding: 10px 15px;
            position: absolute;
            margin: 0;
            background-color: white;
            width: 100%;
            z-index: 2;
            top: 0;
            border-bottom: 1px solid #d6d6d6;
        }

        &__search {
            margin: 0 !important;
            min-height: auto !important;
            padding: 0 !important;
        }

        &__item {
            font-size: 12px;
            border-bottom: 1px solid rgba(151,151,151, .2);
            padding: 10px 0 10px 40px;

            p {
                margin: 0;
                line-height: 20px;
                position: relative;
            }

            &:nth-last-child(1) {
                border: none;
            }

            &-note {
                font-size: 11px;
            }

            &-icon {
                font-size: 13px !important;
                position: absolute;
                left: -20px;
                margin-top: 0px;
            }
        }
    }
</style>
