<template>
    <form @submit.prevent="submitForm">
        <div class="d-flex mt-1">
            <mercur-input class="flex-grow-1 mt-n1" v-model="form.voucher" :class="getValidationClass($v, 'voucher')">
                Voucher code
                <template #note>
                    <span class="form-error" v-if="!$v.form.voucher.required">Voucher is required</span>
                </template>
            </mercur-input>
            <mercur-button class="btn btn-primary ml-2 mt-3" type="submit" :disabled="loading">Apply</mercur-button>
        </div>
    </form>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'VoucherInput',
    mixins: [ FormMixin ],
    props: {
        accountId: {},
        order: {},
        total: {},
    },
    data () {
        return {
            form: {},
        }
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.ORDERS.APPLY_VOUCHER
                .replace('{customerId}', this.accountId)
                .replace('{orderId}', this.$route.params.orderId)
                .replace('{voucher}', this.form.voucher)
        },
    },
    validations: {
        form: {
            voucher: {
                required,
            },
        },
    },
    methods: {
        submitForm () {
            const payload = {
                order: {
                    orderLines: this.order.orderLines,
                    orderId: this.order.orderId || this.$uuid.v4(),
                    totals: {
                        total: this.order.totals ? this.order.totals.subtotal : this.total,
                    },
                },
            }

            this.submit(this.action, payload).then(({ data }) => {
                let message
                if (data.data.errors) {
                    message = data.data.errors[0]
                    this.$root.$emit('notification:global', {
                        message: message,
                        type: 'error',
                    })
                    this.form = {}
                    return
                }

                if (data.data.discounts && data.data.discounts.length) {
                    message = 'Voucher was accepted'
                    this.$emit('accepted', {
                        discount: data.data.discounts[0].value,
                        code: this.form.voucher,
                    })

                    this.form = {}
                    this.$root.$emit('notification:global', {
                        message: message,
                    })
                }
            })
        },
    },
}
</script>
