<template>
    <div>
        <h3 class="font-weight-normal mt-1">Payment</h3>
        <table class="table table-borderless table-sm" v-if="Object.keys(order).length">
            <tbody>
                <tr>
                    <td>Products price:</td>
                    <td class="text-right font-weight-bold"><currency :value="order.totals.productsPrice" :type="order.currency"></currency></td>
                </tr>
                <tr>
                    <td>Shipping:</td>
                    <td class="text-right font-weight-bold"><currency :value="order.totals.shippingPrice" :type="order.currency"></currency></td>
                </tr>
                <tr>
                    <td>Discount:</td>
                    <td class="text-right font-weight-bold">- <currency :value="order.totals.reduction" :type="order.currency"></currency></td>
                </tr>
                <tr>
                    <td>Order subtotal:</td>
                    <td class="text-right font-weight-bold"><currency :value="order.totals.subtotal" :type="order.currency"></currency></td>
                </tr>
                <tr>
                    <td>Tax:</td>
                    <td class="text-right font-weight-bold"><currency :value="order.totals.vat" :type="order.currency"></currency></td>
                </tr>
                <tr>
                    <td class="border-top"><strong>Total:</strong></td>
                    <td class="text-right font-weight-bold border-top"><currency :value="order.totals.total" :type="order.currency"></currency></td>
                </tr>
            </tbody>
        </table>
        <hr v-if="Object.keys(order).length && order.payments.length">
        <div v-if="Object.keys(order).length">
            <div v-if="order.payments && Object.keys(order.payments).length" :class="{'scrollable-payments': Object.keys(order.payments).length > 1}" >
                <table class="payment-table">
                    <tbody>
                    <template v-for="(payment, key) in order.payments">
                        <tr :key="payment.paymentMethod + key">
                            <td class="c-data-table__no-wrap">Payment Method:</td>
                            <td data-e2e="paymentMethodInfo" class="c-data-table__bold-cell text-right"><span>{{ payment.paymentMethod }}</span></td>
                        </tr>
                        <tr :key="payment.providerReference + key">
                            <td>PSP Reference:</td>
                            <td data-e2e="paymentPspReferenceInfo" class="c-data-table__bold-cell text-right"><span>{{ payment.providerReference }}</span></td>
                        </tr>
                        <tr :key="payment.sessionReference + key" class="has-border">
                            <td>Merchant Reference:</td>
                            <td class="c-data-table__bold-cell text-right"><span>{{ payment.sessionReference }}</span></td>
                        </tr>
                    </template>
                    <tr>
                        <td data-e2e="paymentTotalPaidInfo" class="c-data-table__no-wrap">Total amount paid:</td>
                        <td class="c-data-table__bold-cell text-right"><span><currency :value="totalAmountPaid" :type="order.currency"></currency></span></td>
                    </tr>
                    <tr>
                        <td>Invoice: </td>
                        <td class="c-data-table__bold-cell text-right"><span v-if="order.invoice">
                        {{ order.invoice.invoiceNumber }}
                </span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <mercur-button class="btn btn-raised w-100 mt-2 mb-3" :disabled="isDownloading" v-if="order.invoice" @click.native.prevent="getDownloadUrl(order.invoice.invoiceUri)" target="_blank">
                <span class="mr-2 text-large"><i class="fas fa-cloud-download-alt download-icon"></i></span>Download Invoice
            </mercur-button>
        </div>
        <div class="text-right mt-3">
            <mercur-button data-e2e="invoiceAddPaymentButton" class="btn btn-primary btn-raised" :disabled="order.orderStatus === 'CANCELLED'" @click.native="isDialogOpen = true">+ Add payment</mercur-button>
        </div>
        <div>
            <mercur-dialog :is-open.sync="isDialogOpen">
                <h3 data-e2e="addNewPaymentModal" class="font-weight-normal mt-1">Add new payment</h3>
                <form @submit.prevent="submitForm">
                    <mercur-select data-e2e="addPaymentMethod" v-model="form.paymentMethod" :class="getValidationClass($v, 'paymentMethod')">
                        <template #label>Payment Method</template>
                        <option value="adyen">Adyen</option>
                        <option value="bill">Bill</option>
                        <template #note>
                            <span class="form-error" v-if="!$v.form.paymentMethod.required">Payment method is required</span>
                        </template>
                    </mercur-select>
                    <mercur-input data-e2e="addPaymentAmount" v-model.number="form.amount.value" type="number" step="any" :class="getValidationClass($v, ['amount', 'value'])">
                        Amount
                        <template #note>
                            <span class="form-error" v-if="!$v.form.amount.value.required">Amount is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input data-e2e="addPaymentReference" v-model="form.providerReference" type="number">
                        PSP Reference / Transaction ID
                    </mercur-input>
                    <mercur-input v-model="form.sessionReference">
                        Merchant reference
                    </mercur-input>
                    <div class="text-right">
                        <mercur-button data-e2e="addPaymentButton" class="btn btn-primary" type="submit" :disabled="loading">Add payment</mercur-button>
                    </div>
                </form>
            </mercur-dialog>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'PaymentInfo',
    mixins: [ FormMixin ],
    data () {
        return {
            isDownloading: false,
            isDialogOpen: false,
            form: {
                amount: {
                    currency: '',
                },
            },
        }
    },
    computed: {
        ...mapState('order', ['order']),
        ...mapGetters('order', ['totalAmountPaid']),
        paymentReferences () {
            let result = []
            if (this.order.invoice) {
                result.push(this.order.invoice.invoiceNumber)
            }

            if (this.order.slips) {
                result.push(...Object.values(this.order.slips).map(slip => slip.slipNumber))
            }

            return result
        },
    },
    methods: {
        ...mapActions('order', ['setOrderPayments', 'setOrderInvoice']),
        getDownloadUrl (objectName) {
            this.isDownloading = true
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD
                .replace('{objectName}', objectName)

            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.isDownloading = false
            })
        },
        submitForm () {
            if (this.$v) {
                this.$v.$touch()
            }

            if (this.$v && this.$v.$invalid) {
                return
            }

            if (!this.form.providerReference && !this.form.sessionReference) {
                this.$root.$emit('notification:global', {
                    message: 'Input PSP Reference or Merchant Reference',
                    type: 'error',
                })
                return
            }
            const url = CONFIG.API.ROUTES.ORDERS.ADD_PAYMENT.replace('{customerId}', this.$route.params.accountId)

            this.form.orderId = this.order.orderId
            this.form.transactionId = this.$uuid.v4()

            if (!this.form.providerReference) {
                this.form.providerReference = this.$uuid.v4()
            }

            if (!this.form.sessionReference) {
                this.form.sessionReference = this.$uuid.v4()
            }

            const payload = JSON.parse(JSON.stringify(this.form))
            payload.amount.value = parseInt(payload.amount.value.toFixed(2) * 100) // to minor units
            payload.amount.currency = this.order.currency

            this.submit(url, payload).then((data) => {
                this.setOrderPayments({
                    data: data.data,
                })
                this.setOrderInvoice({
                    data: data.data,
                })
                this.$root.$emit('notification:global', {
                    message: 'Payment was added',
                })
            }).finally(() => {
                this.$emit('paymentAdded')
                this.isDialogOpen = false
                this.form = {
                    amount: {
                        currency: 'EUR',
                    },
                }
            })
        },
    },
    validations: {
        form: {
            paymentMethod: { required },
            amount: { value: { required } },
        },
    },
}
</script>

<style lang="scss" scoped>
    .divider {
        margin: 10px 0;
    }
    .add-payment-dialog {
        min-width: 400px;
    }
    .payment-table {
        border-collapse: collapse;
        width: 100%;
        td {
            padding: 3px 0;
            vertical-align: top;
        }
    }
    .download-icon {
        font-size: 16px;
    }
    .scrollable-payments {
        border: 1px solid #d6d6d6;
        height: 225px;
        padding: 10px 5px;
        overflow-y: scroll;

        .has-border {
            td {
                border-bottom: 1px solid #d6d6d6;
            }
        }
    }
</style>
