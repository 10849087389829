<template>
    <div class="json-render">
        <div v-for="(value, key) in values" :key="key">
            <div v-if="typeof value !== 'object'"><span>"{{ key }}"</span>: <span>"{{ value }}",</span></div>
            <div v-else>
                <span>"{{ key }}"</span>:
                <span v-if="Array.isArray(value) && !value.length">[],</span>
                <template v-else>
                    <json-render class="json-render--inner" :values="value"></json-render>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JsonRender',
    props: {
        values: {
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
    .json-render {
        padding-left: 25px;

        &--inner {
            border-left: 1px solid #d6d6d6;
        }
    }
</style>
