<template>
    <div class="c-order-view pb-5" v-if="order && customer">
        <template v-if="hasPermission('getOrderByAggregateId')">
            <title-box>
                <div class="row">
                    <div class="col-sm-8">
                        <h1 class="c-order-view__header">Order {{ order.orderNumber }}
                            <span class="c-order-view__title-span">
                                <status-chip v-if="order.quote" color="purple">From quote</status-chip>
                                <status-chip data-e2e="orderViewStatus" v-if="order.orderStatus">{{ order.orderStatus }}</status-chip>
                            </span>
                        </h1>
                        <p data-e2e="orderDateCreated">Order date: {{ order.dateCreated }}</p>
                        <p v-if="order.quote">
                            This order was created from a quote ({{order.cartId}})
                        </p>
                    </div>
                    <div class="col-sm-4 text-right c-order-view__actions-box">
                        <discount-check ref="discountCheck" @add="addEffectDiscounts"></discount-check>

                        <mercur-menu  v-if="Object.keys(order).length">
                            <mercur-button data-e2e="orderViewActionsButton" class="btn btn-raised">Actions</mercur-button>
                            <div slot="dropdown">
                                <mercur-item :disabled="order.orderStatus === 'CANCELLED' || isCompleted" @click.native.prevent="showFunnelDialog = true">Add new orderline</mercur-item>
                                <mercur-item data-e2e="orderViewSendPaymentOption" @click.native.prevent="sendPayPerMail" :disabled="isSending || isOverpaid || order.orderStatus === 'CANCELLED'  || isCompleted">Send pay by mail link</mercur-item>
                                <mercur-item data-e2e="orderViewSendOrderConfirmationNotification" @click.native.prevent="sendOrderConfirmationNotification" :disabled="isSending">Send order confirmation notification</mercur-item>
                                <mercur-item :disabled="order.orderStatus === 'CANCELLED' || isCompleted" @click.native.prevent="setOrderlinesDeliveryAddress">Edit delivery address for all orderlines</mercur-item>
                                <mercur-item data-e2e="orderViewDuplicateOrderOption" @click.native.prevent="triggerDuplicateOrder" :disabled="!canDuplicateOrder">Duplicate order</mercur-item>
                                <mercur-item data-e2e="orderViewCancelOrder" v-if="order && order.orderStatus !== 'CANCELLED'" @click.native.prevent="triggerCancellationDialog()" :disabled="loading || isCompleted">Cancel order</mercur-item>
                                <mercur-item @click.native.prevent="showDiscountDialog = true" :disabled="loading">Add voucher/discount</mercur-item>
                                <mercur-item data-e2e="orderViewAddFreeShippingOption" @click.native.prevent="showFreeShippingDialog = true" :disabled="loading">Add free shipping</mercur-item>
                            </div>
                        </mercur-menu>
                    </div>
                </div>
            </title-box>
            <div class="container-fluid mt-3">
                <div class="row mb-4">
                    <div class="col-4">
                        <mercur-card class="h-100">
                            <customer-info v-if="hasBillingAddress" :billingAddress="order.billingInfo.address" :action="billingUrl"></customer-info>
                        </mercur-card>
                    </div>
                    <div class="col-4">
                        <mercur-card class="h-100">
                            <payment-info v-if="Object.keys(order).length"  @paymentAdded="fetchLog"></payment-info>
                        </mercur-card>
                    </div>
                    <div class="col-4">
                        <mercur-card class="h-100">
                            <slip-list v-if="Object.keys(order).length" :slips="order.slips" :currency="order.currency"></slip-list>
                        </mercur-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <orderline-list @change="fetchLog" @duplicate="triggerDuplicate" @upgrade="triggerUpgrade" :is-paid="isOrderPaid" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mercur-card class="mb-4">
                            <h3 class="font-weight-normal mt-1">Log</h3>
                            <log-list v-if="Object.keys(order).length" ref="log"></log-list>
                        </mercur-card>
                    </div>
                    <div class="col-6">
                        <mercur-card class="mb-4">
                            <h3 class="font-weight-normal mt-1">Payment Events</h3>
                            <payment-events v-if="Object.keys(order).length" />
                        </mercur-card>
                    </div>
                </div>
            </div>

            <div>
                <mercur-dialog data-e2e="orderViewUpgradeOrderlineModal" :is-open.sync="showFunnelDialog" @close="clearPreset" width="70vw" height="90vh">
                    <h2 class="mt-0 font-weight-normal">
                        <template v-if="isUpgrade">You are upgrading/downgrading orderline {{ draftOrderLine.orderLineNumber }}</template>
                        <template v-else>Create new orderline</template>
                        <mercur-select v-model="orderCountry" class="c-add-order__currency" v-if="!order.countryCode">
                            <template #label>Order currency and shop country</template>
                            <option v-for="(value, key) in currencyList" :key="key" :value="key">{{ value.currency }} ({{ key }})</option>
                        </mercur-select>
                    </h2>
                    <div class="row">
                        <div class="col-sm-8">
                            <div v-if="orderCountry">
                                <funnel ref="funnel"
                                        @productAdded="attachOrderLine"
                                        :isShippingOverwritten="hasFreeShipping"
                                        :isPriceEditable="true"
                                        :presetProduct="presetProduct"
                                        :country="orderCountry"
                                >
                                </funnel>
                                <alert type="danger" v-if="hasCountryCurrencyError">Country of the selected address does not match selected currency for the order!</alert>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <pretty-select
                                class="mb-3"
                                data-e2e="deliveryAddressOption"
                                v-if="addresses.length"
                                label="label"
                                placeholder="Select delivery address for orderline"
                                :options="addresses"
                                v-model="selectedDeliveryAddress"
                            />
                            <div class="c-add-order__box" v-if="hasDiscounts">
                                <p class="mt-3"><strong>This order has discounts that will be applied to this orderline.</strong></p>
                                <ul class="discounts-list" v-for="discount in order.discounts" :key="discount.discountId">
                                    <li>Discount type: {{ discount.discountType }}</li>
                                    <li v-if="discount.discountCode !== ''">Discount code: {{ discount.discountCode }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </mercur-dialog>
            </div>

            <div>
                <mercur-dialog :is-open.sync="showDiscountDialog">
                    <h3 class="font-weight-normal">Add voucher/discount for the order</h3>
                    <voucher-input v-if="hasPermission('MerchantCentral/applyVoucher')" :order="order" :accountId="$route.params.accountId" @accepted="setDiscount"/>
                    <div v-if="discountData">
                        <p>Discount code <strong>{{ discountData.code }}</strong> was submitted. Discount value for this code is <strong><currency :value="discountData.discount" :type="order.currency"></currency></strong>
                            <br>
                            If you want to confirm this discount to the order, press the button below.
                        </p>
                        <br>
                        <div class="text-right">
                            <mercur-button class="btn" @click.native="cancelDiscount" :disabled="loading">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" :disabled="loading" @click.native="addDiscount">Confirm discount</mercur-button>
                        </div>
                    </div>
                </mercur-dialog>
            </div>

            <div>
                <mercur-dialog data-e2e="orderViewCancelOrderModal" :is-open.sync="showCancelDialog">
                    <template #header><h2 class="font-weight-normal mt-1 mb-0">Cancel order</h2></template>
                    <div>
                        <p>Please tell us the reasons for cancelling your order</p>
                        <template v-if="reasons">
                            <mercur-select data-e2e="orderViewCancelOrderSelect" v-model="cancellationReason">
                                <template #label>Reasons</template>
                                <option v-for="(reason,index) in reasons" :key="index" :value="reason">{{ reason }}</option>
                            </mercur-select>
                        </template>
                    </div>
                    <template #footer>
                        <mercur-button class="btn" @click.native="showCancelDialog = false" :disabled="loading">Cancel</mercur-button>
                        <mercur-button data-e2e="orderViewCancelOrderButton" class="btn btn-primary" :disabled="loading" @click.native="cancel">Cancel order</mercur-button>
                    </template>
                </mercur-dialog>
            </div>
            <div>
                <mercur-dialog :is-open.sync="isEditingDelivery" width="700px">
                    <template #header><h2 class="font-weight-normal mt-1 mb-0">Set delivery address for orderline</h2></template>
                    <pretty-select
                        class="mb-3"
                        v-if="addresses.length"
                        label="label"
                        placeholder="Addresses"
                        :options="addresses"
                        v-model="selectedAddress"
                    />
                    <table class="table">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>ID</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Order currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="orderline in order.orderLines" :key="orderline.orderId">
                                <td><mercur-checkbox v-model="selectedOrderLines" :value="orderline"></mercur-checkbox></td>
                                <td>{{ orderline.orderLineNumber }}</td>
                                <td>{{ orderline.product.productName }}</td>
                                <td><currency :value="orderline.productPrice" :type="order.currency"></currency></td>
                                <td>{{ order.currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <template #footer>
                        <mercur-button class="btn" @click.native="cancelUpdatingAddresses" :disabled="loading">Cancel</mercur-button>
                        <mercur-button class="btn btn-primary" :disabled="loading" @click.native="updateAddresses">Save</mercur-button>
                    </template>
                </mercur-dialog>
            </div>
            <mercur-dialog data-e2e="duplicateOrderModal" :is-open.sync="isOrderDuplicate" width="70vw" height="90vh">
                <div class="row">
                    <div class="col-8">
                        <mercur-select data-e2e="duplicateOrderModalSelectApp" v-model="draft.applicationId" @selected="setTempShop">
                            <template #label>Select application</template>
                            <option v-for="shop in shops" :key="shop.applicationId" :value="shop.applicationId">{{ shop.applicationName }}</option>
                        </mercur-select>
                        <customer-selector class="mb-2 card-bordered" v-if="draft.applicationId" @selected="setTempCustomer" :isWithTemporaryShop="true" :isUsingVuex="false" />
                        <template v-if="draft.order">
                            <p>Billing address was set for the order</p>
                            <p>Shipping address was set for all orderlines</p>
                        </template>
                        <div class="text-right" v-if="draft.order">
                            <p><strong>Total price: <currency :value="total" :type="temporaryCurrency"></currency> <span v-if="draft.order.discounts">(discount € {{ draft.order.discounts[0].discountEffects.amount }})</span></strong></p>
                            <mercur-button class="btn mr-2" @click.native="cancelDuplicatingOrder" :disabled="loading">Cancel</mercur-button>
                            <mercur-button data-e2e="duplicateOrderDuplicateActionButton" class="btn btn-primary" @click.native="duplicateOrder" :disabled="!canDuplicateOrder">Duplicate Order</mercur-button>
                        </div>
                    </div>
                    <div class="col-4">
                        <div data-e2e="duplicateOrderViewOrderData" v-if="draft.order">
                            <h3 class="font-weight-normal">Order data</h3>
                            <h4 class="m-0 mb-1">Billing Info</h4>
                            <order-address v-if="hasBillingAddress" :address="draft.order.billingInfo.address" />
                            <h4 class="m-0 mb-1">Orderlines</h4>
                            <div v-for="item in draft.order.orderLines" :key="item.orderLineId">
                                <div class="row">
                                    <div class="col-6">
                                        <p><strong>{{ item.product.productName }}</strong></p>
                                        <table class="c-add-order__orderline-attributes">
                                            <tr v-for="(val, key) in item.product.attributes" :key="key">
                                                <th class="c-add-order__orderline-attributes-key">{{ val.attributeName | capitalize }}</th>
                                                <td>{{ val.attributeOption | capitalize }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-6">
                                        <div>
                                            <p class="c-add-order__delivery-title">Delivery address</p>
                                            <order-address :address="item.deliveryInfo.address" />
                                        </div>
                                    </div>
                                </div>
                                <p id="orderlinePrice"><strong>Price: <currency :value="item.productPrice" :type="item.product.prices.currencyCode"></currency></strong>(vat: <currency :value="(item.productVatRate * item.productPrice).toFixed(2)" :type="item.product.prices.currencyCode"></currency>)</p>
                                <p id="orderlineShippingPrice"><strong>Shipping cost: <currency :value="item.shippingPrice" :type="item.product.prices.currencyCode"></currency></strong>(vat: <currency :value="(item.shippingVatRate * item.shippingPrice).toFixed(2)" :type="item.product.prices.currencyCode"></currency>)</p>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </mercur-dialog>
            <mercur-dialog data-e2e="orderViewAddFreeShippingModal" :is-open.sync="showFreeShippingDialog">
                <p>Are you sure you want to add free shipping to this order?</p>
                <template #footer>
                    <mercur-button data-e2e="orderViewAddFreeShippingCancelButton" class="btn" @click.native="showFreeShippingDialog = false">Cancel</mercur-button>
                    <mercur-button data-e2e="orderViewAddFreeShippingConfirmButton" class="btn btn-primary" @click.native="addFreeShipping" :disabled="loading">Confirm</mercur-button>
                </template>
            </mercur-dialog>
        </template>
        <div class="row" v-else>
            <div class="col-12">
                <p>You are not allowed to see this view</p>
            </div>
        </div>
    </div>
    <div class="row mt-3" v-else>
        <div class="col-12">
            <alert type="danger">Fetching of order or customer failed!</alert>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import StatusChip from '@/components/utils/StatusChip'
import CustomerInfo from '@/components/elements/order/CustomerInfo'
import PaymentInfo from '@/components/elements/order/PaymentInfo'
import LogList from '@/components/elements/order/LogList'
import OrderlineList from '@/components/elements/order/OrderlineList'
import { mapActions, mapState, mapGetters } from 'vuex'
import Funnel from '@/components/elements/Funnel'
import PrettySelect from '@/components/utils/PrettySelect'
import CustomerSelector from '@/components/elements/order/CustomerSelector'
import OrderAddress from '@/components/elements/order/Address'
import { totalsCalculator } from '@/utils/helpers'
import VoucherInput from '@/components/elements/order/VoucherInput'
import SlipList from '../components/elements/order/SlipList'
import DiscountCheck from '../components/elements/order/DiscountCheck'
import PaymentEvents from '../components/elements/order/PaymentEvents'
import HelperMethods from '@/components/utils/HelperMethods'
import Alert from '../components/elements/Alert'

export default {
    name: 'OrderOverview',
    components: { StatusChip, CustomerInfo, VoucherInput, LogList, PaymentInfo, OrderlineList, Funnel, PrettySelect, CustomerSelector, OrderAddress, SlipList, DiscountCheck, PaymentEvents, Alert },
    mixins: [HelperMethods],
    data () {
        return {
            loading: false,
            isSending: false,
            showFunnelDialog: false,
            presetProduct: null,
            showCancelDialog: false,
            cancellationReason: '',
            draftOrderLine: null,
            immutableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
            isEditingDelivery: false,
            selectedAddress: null,
            selectedOrderLines: [],
            isOrderDuplicate: false,
            draft: {},
            isWithTemporaryShop: false,
            temporaryCurrency: null,
            showDiscountDialog: false,
            discountData: null,
            isUpgrade: false,
            showFreeShippingDialog: false,
            orderCountry: null,
            selectedDeliveryAddress: null,
            hasCountryCurrencyError: false,
        }
    },
    watch: {
        workingCountriesList: {
            handler (value) {
                if (Object.keys(value).length === 1) {
                    this.$set(this, 'orderCountry', Object.keys(value)[0])
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState('order', ['order', 'orderLine', 'reasons']),
        ...mapState('customer', ['customer', 'addresses']),
        ...mapState('shop', ['shops', 'currencyConversionRates', 'temporaryShop', 'applicationVatProfiles']),
        ...mapGetters('order', ['totalAmountPaid']),
        ...mapGetters('shop', ['workingCountriesList']),

        canDuplicateOrder () {
            if (this.loading) {
                return false
            }
            return !this.order.quote
        },
        rowData () {
            return this.order.orderLines
        },
        billingUrl () {
            if (!Object.keys(this.order).length) {
                return
            }

            return CONFIG.API.ROUTES.ORDERS.BILLING
                .replace('{accountId}', this.$route.params.accountId)
                .replace('{orderId}', this.order.orderId)
        },
        invoiceDownloadUrl () {
            return CONFIG.API.ROUTES.INVOICES.DOWNLOAD
                .replace('{objectName}', this.order.invoice.filename)
        },
        isOverpaid () {
            if (!Object.keys(this.order).length || !this.order.totals) {
                return false
            }
            return parseFloat(this.totalAmountPaid) > parseFloat(this.order.totals.total)
        },
        isCompleted () {
            if (!this.order || !this.order.orderLines || !this.order.orderLines.length) {
                return false
            }
            const arr = this.order.orderLines.map((e) => e.orderLineStatus)
            return arr.every(item => this.immutableStatuses.includes(item))
        },
        isOrderPaid () {
            if (!this.order) {
                return false
            }

            if (typeof this.order.payments === 'object' && Object.keys(this.order.payments).length) {
                return true
            }

            return false
        },
        total () {
            if (!this.draft.order) {
                return
            }
            return totalsCalculator(this.draft.order.orderLines, this.draft.order.freeShipping, this.draft.order.discounts)
        },
        hasFreeShipping () {
            if (!this.order) {
                return false
            }

            if (this.order.freeShipping) {
                return true
            }

            if (this.order.discounts && this.order.discounts.some((e) => e.discountType === 'FREE_SHIPPING')) {
                return true
            }
            return false
        },
        hasDiscounts () {
            if (this.order.discounts && this.order.discounts.length > 0) {
                return true
            }

            return false
        },
        currencyList () {
            if (this.selectedShop && this.selectedShop.applicationId === '0') {
                const orderShop = this.shops.find(e => e.applicationId === this.$route.params.applicationId)
                if (!orderShop || !orderShop.configuration || !orderShop.configuration.workingCountries) {
                    return {}
                }

                return orderShop.configuration.workingCountries
            }

            return this.workingCountriesList
        },
        hasBillingAddress () {
            if (!this.order || !Object.keys(this.order).length) {
                return false
            }

            return this.order.billingInfo && this.order.billingInfo.address
        },
    },
    methods: {
        ...mapActions('order', ['fetchOrder', 'cancelOrder', 'addOrderLineToOrder', 'fetchCancellationReasons', 'updateOrderLineAddress', 'setOrder', 'updateOrderlines']),
        ...mapActions('customer', ['fetchCustomer']),
        ...mapActions('shop', ['checkShop', 'setTemporaryShop', 'fetchApplicationVatProfiles']),
        cancel () {
            this.loading = true
            this.cancelOrder({
                accountId: this.$route.params.accountId,
                orderId: this.order.orderId,
                reason: this.cancellationReason,
            }).then(() => {
                this.loading = false
                this.$root.$emit('notification:global', {
                    message: 'Order was cancelled',
                })
                this.$refs.log.fetchHistory()
                this.showCancelDialog = false
            })
        },
        triggerCancellationDialog () {
            this.showCancelDialog = true
            this.loading = true
            this.fetchCancellationReasons({
                orderId: this.order.orderId,
            }).then(() => {
                this.loading = false
            })
        },
        fetchLog () {
            this.fetchOrder({
                orderId: this.$route.params.orderId,
            })
            this.$refs.log.fetchHistory()
        },
        triggerDuplicate (data) {
            this.showFunnelDialog = true
            this.draftOrderLine = JSON.parse(JSON.stringify(data))
            let attrs = {}
            data.product.attributes.forEach((attribute) => {
                attrs[attribute.attributeName] = attribute.attributeOption
            })
            data.product.attributes = attrs
            this.$set(this, 'presetProduct', JSON.parse(JSON.stringify(data.product)))
        },
        triggerUpgrade (data) {
            this.isUpgrade = true
            this.triggerDuplicate(data)
        },
        clearPreset () {
            this.draftOrderLine = null
            this.$set(this, 'presetProduct', null)
            this.isUpgrade = false
            this.hasCountryCurrencyError = false
            this.selectedDeliveryAddress = null
        },
        sendPayPerMail () {
            this.isSending = true
            const url = CONFIG.API.ROUTES.ORDERS.SEND_PAY_PER_MAIL.replace('{customerId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId)
            this.addJob(url)

            this.post(url, {}).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Pay by mail link was sent to customer',
                })

                const firstEvent = data.data[0]
                const paymentLink = firstEvent.response.parameters.paymentLink || data.data.response.data.parameters.paymentLink || ''
                this.$segmentClient.sendAction(
                    this,
                    'Send Pay-per-mail',
                    {
                        userId: this.customer.accountId,
                        orderId: this.order.orderId, // Payment method used for this specific order
                        id_order: this.order.orderId, // Unique identifier. Combination of order ID and order item ID
                        cartId: this.order.cartId, // Unique identifier of the cart the user actually validated
                        orderNumber: this.order.orderNumber,
                        payment: 'adyen',
                        lastname: this.customer.lastName, // User lastname. Would become necessary if we implement guest logic.
                        firstname: this.customer.firstName, // User firstname. Would become necessary if we implement guest logic.
                        order_name: this.order.orderId, // Unique identifier of the order (similar to orderId)
                        total_paid: this.order.totals.total, // Total amount to be paid by the user
                        paymentlink: paymentLink,
                        orderStateId: this.order.orderStatus,
                        affiliation: this.order.source,
                    }
                )
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.isSending = false
                this.finishJob(url)
                this.$refs.log.fetchHistory()
            })
        },
        sendOrderConfirmationNotification () {
            this.isSending = true
            const url = CONFIG.API.ROUTES.ORDERS.SEND_ORDER_COMPLETE_NOTIFICATION.replace('{customerId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId)
            this.addJob(url)

            this.post(url, {}, 'Order completed notification was sent to customer')
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.isSending = false
                    this.finishJob(url)
                    this.$refs.log.fetchHistory()
                })
        },

        upgradeOrderLine (orderLine) {
            const archivedOrderLine = JSON.parse(JSON.stringify(this.draftOrderLine))
            archivedOrderLine.orderLineStatus = 'ARCHIVED'
            this.updateOrderlines({
                url: CONFIG.API.ROUTES.ORDERS.ORDERLINE.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId),
                new: [orderLine, archivedOrderLine],
                reason: 'Upgrade/Downgrade orderline',
                orderId: this.$route.params.orderId,
            }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Orderline was updated',
                })
            }).finally(() => {
                this.showFunnelDialog = false
                this.clearPreset()
            })
        },

        addOrderLine (orderLine) {
            this.loading = true
            this.addOrderLineToOrder({
                url: CONFIG.API.ROUTES.ORDERS.ORDERLINE.DUPLICATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId),
                payload: {
                    orderLine,
                    discounts: this.order.discounts,
                },
            }).then(() => {
                this.$refs.funnel.clearFunnel()

                this.$root.$emit('notification:global', {
                    message: 'Orderline was added',
                })
            }).finally(() => {
                this.loading = false
                this.showFunnelDialog = false
                this.draftOrderLine = null
            })
        },

        attachOrderLine (product) {
            if (!this.selectedDeliveryAddress) {
                this.$root.$emit('notification:global', {
                    message: 'Please select delivery address',
                    type: 'error',
                })
                this.$refs.funnel.isAddingProduct = false
                return
            }

            if (!this.checkIfMatchesOrderCurrency(this.selectedDeliveryAddress)) {
                this.hasCountryCurrencyError = true
                this.$refs.funnel.isAddingProduct = false
                return
            }

            let productVatProfile = null
            if (product.vatProfile) {
                productVatProfile = product.vatProfile
                delete product.vatProfile
            }

            const url = CONFIG.API.ROUTES.ORDERS.GET_VARIATION
            const payload = {
                product: product,
                productPrice: product.prices.currencySpecificPrices[this.order.currency].salesPrice,
                shippingPrice: product.prices.currencySpecificPrices[this.order.currency].shippingPrice,
                currency: this.$store.getters['shop/calculatedCurrency'](this.orderCountry),
            }

            this.post(url, payload).then((data) => {
                const orderLine = {
                    product: data.data,
                    orderLineId: this.$uuid.v4(),
                    productPrice: product.productPrice,
                    shippingPrice: product.shippingPrice,
                    deliveryInfo: data.data.deliveryInfo,
                }

                if (productVatProfile) {
                    orderLine.vatProfile = productVatProfile
                }

                orderLine.deliveryInfo.address = this.selectedDeliveryAddress
                this.$delete(orderLine.deliveryInfo.address, 'totalRows')

                if (!this.checkShipsTo(orderLine)) {
                    return
                }

                if (this.isUpgrade) {
                    this.upgradeOrderLine(orderLine)
                } else {
                    this.addOrderLine(orderLine)
                }
            })
        },
        setOrderlinesDeliveryAddress () {
            this.isEditingDelivery = true
        },
        updateAddresses () {
            if (!this.selectedOrderLines.length) {
                return
            }

            this.selectedOrderLines.forEach((orderLine) => {
                this.updateDeliveryAddress(orderLine)
            })
        },
        updateDeliveryAddress (orderLine) {
            if (!this.verifyShippingAddress(this.selectedAddress, true)) {
                return
            }

            this.selectedAddress.countryCode = this.countries.find((e) => e.countryName === this.selectedAddress.country).countryShortCode
            const payload = {
                orderLine: {
                    ...this.orderLine,
                    vatProfile: this.applicationVatProfiles[this.selectedAddress.countryCode],
                },
                address: this.selectedAddress,
            }

            const url = CONFIG.API.ROUTES.ORDERS.DELIVERY.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId).replace('{orderLineId}', orderLine.orderLineId)
            this.loading = true
            this.post(url, payload, 'Orderline delivery address was updated').then((data) => {
                this.updateOrderLineAddress({
                    index: this.order.orderLines.findIndex((e) => e.orderLineId === orderLine.orderLineId),
                    address: data.data.deliveryInfo.address,
                })

                if (data.vatData) {
                    const event = data.vatData.data.find(e => e.event === 'OrderTotalsChanged')
                    if (event) {
                        const vats = event.response.data
                        const order = JSON.parse(JSON.stringify(this.order))
                        order.totals = vats.totals
                        order.orderLines.forEach((orderLine, index) => {
                            order.orderLines[index].orderLineTotals = vats.orderLinesTotals[orderLine.orderLineId]
                        })

                        this.setOrder({
                            data: order,
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
                this.isEditingDelivery = false
            })
        },
        cancelUpdatingAddresses () {
            this.isEditingDelivery = false
            this.selectedOrderLines = []
            this.selectedAddress = null
            this.temporaryCurrency = null
        },
        triggerDuplicateOrder () {
            this.isOrderDuplicate = true
            this.$set(this.draft, 'originalOrder', JSON.parse(JSON.stringify(this.order)))
        },
        setTempShop (id) {
            const shop = this.shops.find((e) => e.applicationId === id)
            this.$set(this.draft, 'application', shop)
            this.setTemporaryShop(shop)
        },
        setTempCustomer (customer) {
            this.$set(this.draft, 'customer', customer)
            const orderCopy = JSON.parse(JSON.stringify(this.order))
            this.temporaryCurrency = Object.values(this.temporaryShop.configuration.workingCountries)[0].currency
            const newOrder = {
                billingInfo: {
                    address: this.draft.customer.defaultBillingAddress,
                },
                buyer: {
                    accountId: this.draft.customer.accountId,
                },
                currency: this.temporaryCurrency,
                orderStatus: 'OPEN',
                quote: true,
                orderLines: [],
                countryCode: orderCopy.countryCode,
                language: orderCopy.language,
            }
            orderCopy.orderLines.forEach((orderLine) => {
                const salesPriceEuro = orderLine.product.prices.currencySpecificPrices['original'].salesPrice
                const shippingPriceEuro = 0
                const conversionRate = this.currencyConversionRates['EUR'][this.temporaryCurrency]
                let payload = {
                    orderLineId: this.$uuid.v4(),
                    product: orderLine.product,
                    deliveryInfo: {
                        ...orderLine.deliveryInfo,
                        address: this.draft.customer.defaultShippingAddress,
                    },
                    productPrice: salesPriceEuro * conversionRate,
                    shippingPrice: orderLine.shippingPrice * this.currencyConversionRates[this.order.currency]['EUR'] * conversionRate,
                    productVatRate: orderLine.productVatRate,
                    shippingVatRate: orderLine.shippingVatRate,
                    vatProfile: orderLine.vatProfile,
                }

                payload.product.prices = {
                    currencyCode: this.temporaryCurrency,
                    productCost: orderLine.product.prices.productCost,
                    shippingCost: orderLine.product.prices.shippingCost,
                    totalCost: orderLine.product.prices.totalCost,
                    currencySpecificPrices: {
                        original: orderLine.product.prices.currencySpecificPrices.original,
                        [this.temporaryCurrency]: {
                            salesPrice: salesPriceEuro * conversionRate,
                            currencyCode: this.temporaryCurrency,
                            conversionRate: conversionRate,
                            serviceLevelCost: shippingPriceEuro * conversionRate,
                        },
                    },
                }
                if (payload.product.producer.producerName === null) {
                    payload.product.producer.producerName = ''
                }
                payload.vatProfile.vatCountry = this.countries.find((e) => e.countryName === payload.vatProfile.vatCountry).countryShortCode

                delete payload.product.dateCreated
                delete payload.product.dateUpdated
                delete payload.product.productId

                newOrder.orderLines.push(payload)
            })
            this.$set(this.draft, 'order', newOrder)
        },
        cancelDuplicatingOrder () {
            this.draft = {}
            this.setTemporaryShop(null)
            this.isOrderDuplicate = false
        },
        duplicateOrder () {
            const url = CONFIG.API.ROUTES.ORDERS.ADD.replace('{accountId}', this.draft.customer.accountId)
            this.loading = true
            this.isWithTemporaryShop = true
            const applicationId = this.temporaryShop.applicationId
            const customerId = this.draft.customer.accountId
            this.post(url, this.draft.order, 'Order was duplicated').then((data) => {
                setTimeout(() => {
                    this.$router.push({
                        name: 'CustomerView',
                        params: {
                            applicationId: applicationId,
                            customerId: customerId,
                        },
                    })
                }, 500)
            }).finally(() => {
                this.loading = false
                this.isWithTemporaryShop = false
                this.cancelDuplicatingOrder()
            })
        },
        listen () {
            this.$router.push({
                name: 'OrdersWrapper',
            })
        },
        setDiscount (data) {
            this.discountData = data
        },
        cancelDiscount () {
            this.showDiscountDialog = false
            this.discountData = null
        },
        addFreeShipping () {
            this.addDiscount({
                code: 'MANUALLY_ADDED',
                amount: 0,
                type: 'FREE_SHIPPING',
            })
        },
        async addDiscount (preset = null) {
            const url = CONFIG.API.ROUTES.ORDERS.ADD_ORDER_DISCOUNT.replace('{customerId}', this.$route.params.accountId)
            const payload = {
                orderId: this.$route.params.orderId,
                discount: {
                    discountCode: !preset.target ? preset.code : this.discountData.code,
                    discountEffects: {
                        type: 'absolute',
                        amount: !preset.target ? preset.amount : this.discountData.discount,
                    },
                    discountType: !preset.target ? preset.type : 'COUPON',
                },
            }
            this.loading = true
            await this.post(url, payload, 'Discount was added').then(({ data }) => {
                this.setOrder({
                    data: data,
                })
                this.$refs.log.fetchHistory()
                this.showFreeShippingDialog = false
                this.cancelDiscount()
            }).catch(({ data }) => {
                let message = 'Something went wrong.'
                if (data.data && data.data.length && data.data[0].error) {
                    message = data.data[0].error
                }

                this.$root.$emit('notification:global', {
                    message: message,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
        addEffectDiscounts (event) {
            const url = CONFIG.API.ROUTES.ORDERS.SET_DISCOUNTS.replace('{orderId}', this.$route.params.orderId).replace('{customerId}', this.$route.params.accountId)
            let payload = []
            if (this.order.discounts && Array.isArray(this.order.discounts)) {
                payload = this.order.discounts
            }

            payload = [
                ...payload,
                ...event,
            ]
            this.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Discounts were added',
                })
                this.setOrder({
                    data: data,
                })
                this.$refs.discountCheck.discountsAdded = true
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: 'There was an error adding discount.',
                    type: 'error',
                })
                this.$refs.discountCheck.isAdding = false
            }).finally(() => {
                this.$refs.discountCheck.isAdding = false
            })
        },
    },
    created () {
        this.checkShop(this.$route.params.applicationId)
        if (!this.hasPermission('getOrderByAggregateId')) {
            return
        }
        this.fetchOrder({
            orderId: this.$route.params.orderId,
            purgeOrderlines: true,
        }).then(() => {
            this.orderCountry = this.order.countryCode ? this.order.countryCode.toUpperCase() : ''
            this.fetchCustomer({
                accountId: this.$route.params.accountId,
            })
        })
    },
    mounted () {
        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}
</script>

<style lang="scss" scoped>
    .c-order-view {
        &__funnel-dialog {
            min-width: 70vw;
            min-height: 90vh;
        }
        &__cancel-dialog {
            min-width: 30rem;
        }
        &__duplicate-order {
            min-width: 90vw;
            min-height: 90vh;
        }
        &__discount-dialog {
            min-width: 550px;
        }
    }

    .discounts-list {
        padding: 0 0 10px;
        margin: 10px 0;
        list-style: none;
        border-bottom: 1px solid rgba(0,0,0,.12);

        &:last-child {
            border: none;
        }

        li {
            padding: 2px 0;
        }
    }

</style>
