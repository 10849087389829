<template>
    <div class="c-payload-display">
        <p class="c-payload-display__action" @click="isVisible = !isVisible"><span>View payload </span><span><i v-if="!isVisible" class="fas fa-chevron-down"></i><i v-else class="fas fa-chevron-up"></i></span></p>
        <div v-if="isVisible" class="c-payload-display__json">
            <json-render :values="payload"></json-render>
        </div>
    </div>
</template>

<script>
import JsonRender from './JsonRender'
export default {
    name: 'PayloadDisplay',
    components: { JsonRender },
    props: {
        payload: {
            required: true,
        },
    },
    data () {
        return {
            isVisible: false,
        }
    },
}
</script>

<style lang="scss" scoped>
    .c-payload-display {
        &__action {
            cursor: pointer;
            margin: 0;
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }

        &__json {
            margin-left: -20px;
        }

        pre {
            margin: 0;
            white-space: pre-wrap;
        }
    }
</style>
