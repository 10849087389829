<template>
    <div>
        <mercur-card class="mb-4">
            <h3 class="font-weight-normal mt-1">Products</h3>
            <merchant-data-table
                :attrs="{'data-e2e': 'orderViewOrderlineTable'}"
                v-if="order"
                class="c-merchant-table--dense"
                ref="table"
                :items="order.orderLines"
                :is-default-size="false"
                :options="options" />
        </mercur-card>

        <mercur-dialog :is-open.sync="isDialogOpen" width="600px">
            <h3>Are you sure you want to cancel this orderline?</h3>
            <p>By removing orderLine, you are affecting order totals which will trigger creation of payment slip!</p>
                <mercur-textarea v-model="form.reason" :class="getValidationClass($v, 'reason')">
                    Reason for cancellation
                    <template #note>
                        <span class="form-error" v-if="!$v.form.reason.required">Reason is required</span>
                    </template>
                </mercur-textarea>
            <template #footer>
                <mercur-button class="btn" :disabled="loading" @click.native="isDialogOpen = false">Discard changes</mercur-button>
                <mercur-button class="btn btn-primary" @click.native="cancel" :disabled="loading">Confirm</mercur-button>
            </template>
        </mercur-dialog>

        <div>
            <mercur-dialog :is-open.sync="isDialogArchive">
                <h3 class="font-weight-normal">Are you sure you want to archive this orderline</h3>
                <p>Once you archive it, customer won't be able to see it.</p>
                <template #footer>
                    <mercur-button class="btn" :disabled="loading" @click.native="isDialogArchive = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native="archiveOrderline" :disabled="loading">Confirm</mercur-button>
                </template>
            </mercur-dialog>
        </div>

        <edit-dialog :fields="draft.orderLine" ref="editDialog" @change="modifyOrderline" @closed="unsetOrderline">Edit orderline - {{ draft.orderLine && draft.orderLine.orderLineNumber }}</edit-dialog>
    </div>
</template>

<script>
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import AttributeList from '@/components/elements/order/AttributeList'
import EditDialog from '@/components/elements/EditDialog'
import { mapState, mapActions } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import Currency from '@/components/elements/Currency'

export default {
    name: 'OrderlineList',
    components: { MerchantDataTable, EditDialog },
    mixins: [ FormMixin ],
    props: {
        isPaid: {
            default: false,
        },
    },
    data () {
        return {
            draft: {},
            form: {},
            isDialogOpen: false,
            cancellableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
            isDialogArchive: false,
            options: {
                columns: {
                    'Order line': {
                        field: 'orderLineNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderLineView',
                                params: {
                                    accountId: this.$route.params.accountId,
                                    orderId: this.$route.params.orderId,
                                    orderLineId: data.orderLineId,
                                },
                            }
                        },
                        icon: (data) => {
                            return data.reprintedFromOrderLineId ? 'content_paste' : null
                        },
                        tooltip: (data) => {
                            return data.reprintedFromOrderLineId ? 'This orderline is a reprint.' : null
                        },
                    },
                    'Product': {
                        field: 'product.productName',
                    },
                    'SKU': {
                        field: 'product.sku',
                    },
                    'Product Attributes': { field: '', cellRendererFramework: AttributeList, width: 400 },
                    'Product Price': {
                        field: 'orderLineTotals.productsPrice',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.product.prices.currencyCode,
                            }
                        },
                    },
                    'Shipping price': {
                        field: 'orderLineTotals.shippingPrice',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.product.prices.currencyCode,
                            }
                        },
                    },
                    'Subtotal': {
                        field: 'orderLineTotals.subtotal',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.product.prices.currencyCode,
                            }
                        },
                    },
                    'Total': {
                        field: 'orderLineTotals.total',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.product.prices.currencyCode,
                            }
                        },
                    },
                    'Quantity': { field: 'product.quantity', width: 150 },
                    'Date Created': { field: 'dateCreated' },
                    'Status': { field: 'orderLineStatus', statusChip: true, width: 255 },
                },
                actions: {
                    type: 'dropdown',
                    attr: {
                        'data-e2e': 'editOrderlineActions',
                    },
                    items: [
                        {
                            text: 'Edit orderline',
                            onClick: ({ data }) => {
                                this.triggerEdit(data)
                            },
                            isDisabled: () => {
                                return this.order.orderStatus === 'CANCELLED'
                            },
                        },
                        {
                            text: 'Duplicate orderline',
                            attr: {
                                'data-e2e': 'orderlineDuplicateAction',
                            },
                            onClick: ({ data }) => {
                                this.triggerDuplicate(data)
                            },
                            isDisabled: () => {
                                return this.order.orderStatus === 'CANCELLED'
                            },
                            isHidden: () => {
                                return !this.hasPermission('addLineToOrder')
                            },
                        },
                        {
                            text: 'Cancel orderline',
                            attr: {
                                'data-e2e': 'orderlineCancel',
                            },
                            onClick: ({ data }) => {
                                this.triggerDelete(data)
                            },
                            isDisabled: () => {
                                return !this.isPaid
                            },
                            isHidden: ({ data }) => {
                                return this.isCancelable(data)
                            },
                        },
                        {
                            text: 'Upgrade/downgrade orderline',
                            attr: {
                                'data-e2e': 'orderlineUpgradeAction',
                            },
                            onClick: ({ data }) => {
                                this.triggerUpgrade(data)
                            },
                            isHidden: ({ data }) => {
                                if (!data) {
                                    return
                                }
                                return ['ARCHIVED', 'CANCELLED'].includes(data.orderLineStatus)
                            },
                        },
                        {
                            text: 'Archive orderline',
                            tooltip: 'Will not be visible to customer',
                            onClick: ({ data }) => {
                                this.triggerArchive(data)
                            },
                            isHidden: ({ data }) => {
                                return this.isCancelable(data)
                            },
                        },
                    ],
                },
                customCellClass: 'c-standard-table__cell--align-top',
                pagination: false,
            },
        }
    },
    computed: {
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapActions('order', ['cancelOrderLine', 'addOrderLineToOrder', 'updateOrderlines']),
        isCancelable (data) {
            const numberOfOrderLines = this.order.orderLines.length
            if (numberOfOrderLines < 2 || ['ARCHIVED', 'CANCELLED'].includes(data.orderLineStatus) || !this.hasPermission('cancelOrderLine')) {
                return true
            }
            if (this.order.orderLines.filter(e => ['CANCELLED', 'ARCHIVED'].includes(e.orderLineStatus)).length === numberOfOrderLines - 1) {
                return true
            }
            return false
        },
        triggerDelete (data) {
            this.draft.removeOrderLine = data
            this.isDialogOpen = true
        },
        triggerDuplicate (data) {
            this.$emit('duplicate', data)
        },
        triggerUpgrade (data) {
            this.$emit('upgrade', data)
        },
        triggerArchive (data) {
            this.$set(this.draft, 'orderLineToArchive', {
                ...data,
                currency: data.product.prices.currencyCode,
            })
            this.isDialogArchive = true
        },
        triggerEdit (data) {
            this.$set(this.draft, 'orderLine', {
                ...data,
                currency: data.product.prices.currencyCode,
            })
        },
        archiveOrderline () {
            const payload = JSON.parse(JSON.stringify(this.draft.orderLineToArchive))
            payload.orderLineStatus = 'ARCHIVED'
            this.modifyOrderline(payload, this.draft.orderLineToArchive)
        },
        cancel () {
            if (this.$v) {
                this.$v.$touch()
            }

            if (this.$v && this.$v.$invalid) {
                return
            }
            this.loading = true
            this.cancelOrderLine({
                url: CONFIG.API.ROUTES.ORDERS.ORDERLINE.CANCEL.replace('{accountId}', this.$route.params.accountId)
                    .replace('{orderId}', this.order.orderId)
                    .replace('{orderLineId}', this.draft.removeOrderLine.orderLineId),
                reason: this.form.reason,
            }).then(() => {
                this.isDialogOpen = false
                this.form = {}
                this.draft = {}
                this.loading = false
                this.$refs.table.refreshCells()
                this.$emit('change')
                this.$segmentClient.sendAction(this, 'Cancel Order Orderline', { userId: this.$route.params.accountId })
                this.$root.$emit('notification:global', {
                    message: 'Order Line was cancelled',
                })
            })
        },
        unsetOrderline () {
            this.$set(this, 'draft', {})
        },
        modifyOrderline (payload, customDraft = null) {
            this.loading = true

            this.updateOrderlines({
                orderId: this.$route.params.orderId,
                url: CONFIG.API.ROUTES.ORDERS.ORDERLINE.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId),
                new: [payload],
            }).then(() => {
                this.$emit('change')
                this.$refs.editDialog.resetForm()
                this.$root.$emit('notification:global', {
                    message: 'Orderline was updated',
                })
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: err,
                })
            }).finally(() => {
                this.$set(this, 'draft', {})
                this.loading = false
            })
        },
    },
    validations: {
        form: {
            reason: {
                required,
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-wide-dialog {
        width: 600px;

        &__btn-submit {
            margin-left: 10px;
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 12px;
                text-transform: uppercase;

                span {
                    font-weight: bold;
                }
            }
        }

        &__product-info {
            border: 1px solid #e2e2e2;
            padding: 5px 15px;
            margin-bottom: 20px;
        }

        &__product-name {
            font-weight: bold;
            margin-top: 0;

            span {
                font-size: 13px;
                font-weight: normal;
            }
        }
    }
</style>
