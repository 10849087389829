<template>
    <span>
        <template v-if="hasTalonOneConfig">
            <mercur-button data-e2e="orderViewCheckDiscountsButton" class="btn btn-yellow text-black mr-2" @click.native="checkForDiscounts">Check for discounts</mercur-button>
            <mercur-dialog data-e2e="orderViewCheckDiscountsModal" :is-open.sync="isOpen">
                 <template v-if="discountsAdded">
                    <alert type="success">Discounts were successfully added to the order.</alert>
                    <mercur-button class="btn mt-2" @click.native="rejectDiscounts">Close</mercur-button>
                </template>
                <div class="text-center" v-else-if="isChecking">
                    <p class="mb-3">We are checking for discounts...</p>
                    <mercur-spinner></mercur-spinner>
                </div>
                <template v-else-if="!hasDiscounts || !effects.discounts || !effects.discounts.length">
                    <alert type="info">There are no discounts for this order!</alert>
                    <mercur-button data-e2e="orderViewDiscountsOkayButton" class="btn mt-2" @click.native="rejectDiscounts">Okay</mercur-button>
                </template>
                <template v-else-if="hasDiscounts && effects.discounts.length">
                    <template v-if="notValid">
                        <alert type="danger">Sorry these discounts are not valid anymore</alert>
                        <mercur-button @click.native="rejectDiscounts" class="btn mt-2">Close</mercur-button>
                    </template>
                    <template v-else>
                        <alert type="success">There are discounts for this order!</alert>
                        <ul class="discount-list">
                            <li v-for="(discount, index) in effects.discounts" :key="discount.code">
                                <mercur-checkbox id="discounts" v-model="selectedDiscounts" :value="index">
                                    Discount with name<strong>{{ discount.name }}</strong> with total value of <strong><currency :value="discount.value" :type="order.currency"></currency></strong>
                                </mercur-checkbox>
                            </li>
                        </ul>
                        <br>
                        <div v-if="effects.notifications" class="discount-notifications">
                            <div class="discount-notifications-message" v-for="notification in effects.notifications"
                                 :key="notification.title">
                                <h3>{{ notification.title }}!</h3>
                                <p>{{ notification.body }}</p>
                            </div>
                        </div>
                        <div class="mt-4">
                            <mercur-button class="btn" :disabled="isAdding" @click.native="rejectDiscounts">Reject Discounts</mercur-button>
                            <mercur-button class="btn btn-primary" :disabled="isAdding || !selectedDiscounts.length" @click.native="acceptDiscounts">Accept Discounts</mercur-button>
                        </div>
                    </template>
                </template>
            </mercur-dialog>
        </template>
    </span>
</template>

<script>
import CONFIG from '@root/config'
import Currency from '@/components/elements/Currency'
import Alert from '@/components/elements/Alert'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'DiscountCheck',
    components: { Currency, Alert },
    data () {
        return {
            isChecking: false,
            isOpen: false,
            hasDiscounts: null,
            effects: null,
            notValid: null,
            isAdding: false,
            discountsAdded: false,
            selectedDiscounts: [],
        }
    },
    computed: {
        ...mapState('order', ['order']),
        ...mapState('shopconfig', ['shopConfigs']),
        hasTalonOneConfig () {
            if (!this.shopConfigs || !this.shopConfigs.GENERAL) {
                return
            }

            return !!this.shopConfigs.GENERAL.find((e) => e.configurationName === 'talonOne')
        },
    },
    methods: {
        ...mapActions('shopconfig', ['fetchShopConfig']),
        checkForDiscounts () {
            const url = CONFIG.API.ROUTES.ORDERS.DISCOUNTS.CHECK
                .replace('{customerId}', this.$route.params.accountId)
                .replace('{orderId}', this.$route.params.orderId)

            this.isOpen = true
            this.isChecking = true
            this.get(url).then(({ data }) => {
                this.isChecking = false
                if (data.errors) {
                    this.hasDiscounts = false
                    return
                }

                this.hasDiscounts = true
                this.effects = data.effects
            }).finally(() => {
                this.isChecking = false
            })
        },
        rejectDiscounts () {
            this.effects = null
            this.hasDiscounts = null
            this.isOpen = false
            this.notValid = null
            this.selectedDiscounts = []
            this.discountsAdded = false
        },
        acceptDiscounts () {
            const url = CONFIG.API.ROUTES.ORDERS.DISCOUNTS.CONFIRM_CHECK
                .replace('{customerId}', this.$route.params.accountId)
                .replace('{orderId}', this.$route.params.orderId)
            this.isAdding = true
            this.get(url).then(({ data }) => {
                if (data.errors) {
                    this.notValid = true
                    return
                }

                this.effects = data.effects
                if (this.effects.discounts) {
                    const discounts = []
                    this.effects.discounts.forEach((discount, index) => {
                        if (!this.selectedDiscounts.includes(index)) {
                            return
                        }

                        discounts.push({
                            discountCode: discount.code || 'NO_CODE',
                            discountEffects: {
                                type: 'absolute',
                                amount: discount.value,
                            },
                            discountType: 'DISCOUNT',
                            orderLineId: discount.orderLineId,
                        })
                    })

                    this.$emit('add', discounts)
                }
            })
        },
    },
    created () {
        if (!this.shopConfigs || !this.shopConfigs.GENERAL) {
            this.fetchShopConfig({
                category: 'GENERAL',
            })
        }
    },
}
</script>

<style lang="scss" scoped>
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .discount-list {
        list-style: none;
        padding: 0;
        margin: 15px 0;

        li {
            padding: 12px;
            background-color: rgba(0, 0, 0, 0.06);
            margin-bottom: 4px;
        }
    }

    .discount-notifications {
        border: 1px solid rgba(0, 0, 0, 0.06);

        &-message {
            padding: 8px 15px;

            h3 {
                margin: 0;
            }
        }
    }
</style>
