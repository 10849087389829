<template>
    <div>
        <h3 class="font-weight-normal mt-1">Order slips</h3>
        <p v-if="!slips || slips.length === 0">No slips for this order.</p>
        <div v-if="slips" class="slips-wrapper">
            <div class="slip-row border border-radius" v-for="(value, key) in sortedSlips" :key="key">
                <div class="d-flex">
                    <div class="flex-grow-1"><strong>{{ value.slipNumber }}</strong>
                        <span @click="downloadSlip(value)"><i class="fas fa-cloud-download-alt slip-row__icon"></i></span>
                    </div>
                    <div><status-chip>{{ value.status }}</status-chip></div>
                </div>
                <div>
                    <strong>Type:</strong> {{ value.type }}
                </div>
                <div>
                    <strong>Reason:</strong> {{ value.reason }}
                </div>
                <table class="table table-borderless table-xs">
                    <tbody>
                        <tr>
                            <td>Products price:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.productsPrice" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td>Shipping price:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.shippingPrice" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td>Products vat:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.productVat" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td>Shipping vat:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.shippingVat" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td>Discounts:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.reduction" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td>Subtotal:</td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.subtotal" :type="currency"></currency></td>
                        </tr>
                        <tr>
                            <td><strong>Total:</strong></td>
                            <td class="text-right font-weight-bold"><currency :value="value.totals.total" :type="currency"></currency></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import StatusChip from '@/components/utils/StatusChip'
import CONFIG from '@root/config'

export default {
    name: 'SlipList',
    components: { StatusChip },
    props: {
        slips: {
            default: () => {},
        },
        currency: {
            default: 'EUR',
        },
    },
    computed: {
        sortedSlips () {
            if (!Object.keys(this.slips).length) {
                return []
            }

            const originalSlips = JSON.parse(JSON.stringify(this.slips))
            return Object.values(originalSlips).sort((a, b) => (a.slipNumber > b.slipNumber) ? 1 : ((b.slipNumber > a.slipNumber) ? -1 : 0))
        },
    },
    methods: {
        downloadSlip (data) {
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD.replace('{objectName}', data.slipUri)
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                this.$emit('error', err.response)
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .slips-wrapper {
        max-height: 450px;
        overflow-y: auto;
    }
    .slip-row {
        padding: 10px 5px;

        &__icon {
            margin-left: 7px;
            vertical-align: middle;
            margin-top: -2px;
            font-size: 22px !important;
            cursor: pointer;
            color: black !important;
        }
    }
</style>
